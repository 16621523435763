var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "checkTable",
          attrs: {
            title: _vm.title,
            columns: _vm.gridColumns,
            data: _vm.review.checks,
            gridHeight: _vm.gridHeight,
            selection: "multiple",
            rowKey: "mdmChemReviewCheckId",
            columnSetting: false,
            filtering: false,
            usePaging: false,
            hideBottom: true,
            editable: _vm.editable && !_vm.disabled,
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "process"
                    ? [
                        _vm.checkEditable
                          ? _c(
                              "q-btn-group",
                              { attrs: { outline: "" } },
                              [
                                [
                                  props.row.mdmChemReviewCheckStepCd ===
                                  "CRC0000005"
                                    ? _c("c-btn", {
                                        attrs: {
                                          label: "검토완료",
                                          icon: "check",
                                        },
                                        on: {
                                          btnClicked: function ($event) {
                                            return _vm.checkComplete(props)
                                          },
                                        },
                                      })
                                    : props.row.mdmChemReviewCheckStepCd ===
                                      "CRC0000010"
                                    ? _c("c-btn", {
                                        attrs: {
                                          label: "검토취소",
                                          icon: "cancel",
                                        },
                                        on: {
                                          btnClicked: function ($event) {
                                            return _vm.checkCancel(props)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                              ],
                              2
                            )
                          : _vm._e(),
                        props.row.reviewDt
                          ? [
                              _c(
                                "div",
                                [
                                  _vm._v(" 검토일시 : "),
                                  _c(
                                    "font",
                                    {
                                      staticClass: "text-primary",
                                      staticStyle: {
                                        "font-size": "1.1em",
                                        "font-weight": "800",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(props.row.reviewDt) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBLEXCEPT", icon: "remove" },
                        on: { btnClicked: _vm.addRemove },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBLADD", icon: "add" },
                        on: { btnClicked: _vm.addCheck },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }